import $ from 'jquery';

let allData = {};
console.log('funcLoadInfoForModal')
const buildLeftDOM = function buildLeftDOM(input) {
    let out = '';
    console.log('input', input)
    for (let element in input) {
        console.log('element', element)
        let struct = input[element];
        console.log('struct', struct)
        if (struct.users.length > 0) {
            out += '<div class="accordion employees-selection__group">';
            out += '<div class="accordion__title" data-id="' + struct.struct_id + '" role="tab">';
            out += '<a href="#departament' + struct.struct_id + '" data-id="' + struct.struct_id + '" class="collapsed" data-toggle="collapse" data-parent="#accordion">' + struct.struct_name + '</a>';
            out += '<button class="group__button ' + (struct.all_user_selected === true ? "group__button-active" : "") + '" type="button"><span class="group__icon"></span></button>';
            out += '</div>';
            out += '<div class="accordion__content collapse" id="departament' + struct.struct_id + '" role="tabpanel" aria-labelledby="' + struct.struct_id + '">';
            for (let userElement in struct.users) {
                let user = struct.users[userElement];
                out += '<div class="employees-selection__employee">';
                out += '<label class="employee" for="user' + user.user_id + '">';
                out += '<input class="employee__checkbox" type="checkbox" ' + (user.user_selected ? "checked" : "") + ' name="attestation_workers" id="user' + user.user_id + '" value="' + user.user_id + '">';
                out += '<div class="employee__name">' + user.user_fio + '</div>';
                out += '</label>';
                out += '</div>';
            }
            out += '</div>';
            out += '</div>';
        }
    }
    return out;
};
const buildRightDOM = function buildRightDOM(input) {
    let out = '';
    let rendered_users = [];
    for (let element in input) {
        let struct = input[element];

        for (let userElement in struct.users) {
            let user = struct.users[userElement];
            // Если работник выбран и до этого не был отрендерен
            if (user.user_selected === true && !rendered_users.includes(user.user_id)) {
                out += '<li data-id="' + user.user_id + '" data-structid="' + struct.struct_id + '" class="employees__list_item employees__list_person">' + user.user_fio + '</li>';
                // Добавляем работника в число уже отрендереных
                rendered_users.push(user.user_id);
            }
        }
    }
    return out;
};
const RenderLeftSide = function RenderLeftSide(html) {
    let el = $('.employees-selection_left .employees-selection__wrapper');
    el.html(html);
};
const RenderRightSide = function RenderRightSide(html) {
    let el = $('.employees__list');
    el.html(html);
};

$(document).on('click', '.accordion__title .group__button', function () {
    console.log('1')
    let id = $(this).siblings('a').attr('data-id');
    let i = searchStructById(id);
    console.log('id', id)
    console.log('i', i)
    // make_selected is true when all_user_selected is false
    let make_selected = !allData[i].all_user_selected;
    console.log('make_selected', make_selected)
    // Собираем айдишники пользователей которые будут включены/исключены из аттестаии
    let selected_users_ids = allData[i].users.map(function (user) {
        return user.user_id;
    });
    console.log('selected_users_ids', selected_users_ids)

    // В разных отделах могут быть одни и те же работники и если мы их выбрали в отдном отделе
    // то отметить их выбранными и в другом
    allData.forEach(function (structure, index) {
        // Если это выбранный отедл
        if (index === i) {
            // Включаем/отключаем отдел
            structure.all_user_selected = make_selected;
            // Включаем/отключаем всех пользователей этого отдела
            structure.users.forEach(function (user) {
                user.user_selected = make_selected
            })
            // Если это не выбранный отдел
        } else {
            // Ищем работников, которые должны быть включены/отключены
            let intersection_users = structure.users.filter(function (user) {
                return selected_users_ids.indexOf(user.user_id) !== -1
            });

            // Если влючаем/отключаем всех работников отдела, то нужно включить/отключить и сам отдел
            if (structure.users.length === intersection_users.length) {
                structure.all_user_selected = make_selected
            }
            // Включаем/отключаем работников
            intersection_users.forEach(function (user) {
                user.user_selected = make_selected
            })
        }
    });
    Render();
});
$(document).on('change', '.employee__checkbox', function () {
    console.log('2')
    let struct = ($(this).parent().parent().parent().attr('id')).replace('departament', '');
    let structId = searchStructById(struct);
    let id = $(this).attr('value');
    let userNum = searchUserById(structId, id);

    if (allData[structId].users[userNum].user_selected === true) {
        if (allData[structId].all_user_selected === true) {
            allData[structId].all_user_selected = false;
            $('[data-id="' + struct + '"] .group__button').removeClass('group__button-active');
        }
        allData[structId].users[userNum].user_selected = false;
    } else {
        allData[structId].users[userNum].user_selected = true;
        let temp = true;
        allData[structId].users.map(function (user) {
            if (user.user_selected == false) {
                temp = false;
            }
        });
        if (temp == true) {
            allData[structId].all_user_selected = true;
        }
    }
    // отмечаем у одного пользователя во всех отделах, прохождение тестов
    // по одному клику
    let current_status_testing = allData[structId].users[userNum].user_selected;
    for (let step = 0; step < allData.length; step++) {
        let userNum = searchUserById(step, id);
        if (allData[step].users[userNum]) {
            allData[step].users[userNum].user_selected = current_status_testing
        }
        let name_structure = allData[step].struct_name;
        // Проходимся по пользователям в отделе
        allData[step].users.map(function (user) {
            check: {
                allData[step].all_user_selected = user.user_selected;
                $('[data-id="' + name_structure + '"] .group__button').addClass('group__button-active');
                // Если хотя бы один пользователь в отделе не выбран, удаляем active с кнопки отдела
                if (!user.user_selected) {
                    $('[data-id="' + name_structure + '"] .group__button').removeClass('group__button-active');
                    break check;
                }
            }
        });
    }
    RenderLeftSide(buildLeftDOM(allData));
    RenderRightSide(buildRightDOM(allData));

    // если все чекбоксы активны, добавить класс
    // и наоборот
    let dataLength = allData[structId].users.length;
    let usersLength = $('#' + struct).find(':checked').length;
    if (dataLength == usersLength) {
        $('[data-id="' + struct + '"] .group__button').addClass('group__button-active');
    } else if (dataLength == 1) {
        $('[data-id="' + struct + '"] .group__button').removeClass('group__button-active');
    }
    RenderLeftSide(buildLeftDOM(allData));
    RenderRightSide(buildRightDOM(allData));
});

$(document).on('click', '.employees__list_item', function () {
    console.log('3')
    let
        struct = $(this).attr('data-structid'),
        structId = searchStructById(struct),
        id = $(this).attr('data-id'),
        userNum = searchUserById(structId, id);

    allData[structId].all_user_selected = false;
    allData[structId].users[userNum].user_selected = false;
    // цикл для удаления из правой части popup
    // одного пользователя из разных отделов по одному клику
    for (let step = 0; step < allData.length; step++) {
        allData[step].users.map(function (user) {
            if (user.user_id == id) {
                user.user_selected = false
            }
        });
    }
    // TODO: поменять на RenderRightSide и сделать левую часть не через рендер
    Render();
});

$(document).on('keyup', '.employees-selection__search input', function (e) {
    let text = $(this).val().toLowerCase();
    if (text.trim() == '') {
        $('.employees-selection__wrapper .employees-selection__employee').each(function (i) {
            let elem = $(this);
            elem.removeClass('hidden');
            elem.parent().removeClass('show');
            elem.parent().prev().find('a').addClass('collapsed');
        });
    } else {
        $('.employees-selection__wrapper .employees-selection__employee').each(function (i) {
            let elem = $(this);
            let name = elem.find('.employee__name').html().toLowerCase().trim();
            if (name.indexOf(text) === -1) {
                elem.addClass('hidden');
                elem.parent().prev().find('a').addClass('collapsed');
                elem.parent().parent().parent().removeClass('show');
            } else {
                elem.removeClass('hidden');
                elem.parent().prev().find('.collapsed').removeClass('collapsed');
                elem.parent().addClass('show');
            }
        });
    }
});

$(document).on('click', '.accordion__title', function (e) {
    $('.accordion__content.collapse.show').not($(this).closest('.accordion.employees-selection__group').find('.accordion__content.collapse')).removeClass('show')
    $('.accordion__title').find('a:not(.collapsed)').not($(this).closest('.accordion.employees-selection__group').find('a')).addClass('collapsed');
    if ($(this).closest('.accordion.employees-selection__group').find('a').hasClass('collapsed')) {
        $(this).closest('.accordion.employees-selection__group').find('.accordion__content.collapse').addClass('show');
        $(this).closest('.accordion.employees-selection__group').find('a').removeClass('collapsed');
    } else {
        $(this).closest('.accordion.employees-selection__group').find('a').addClass('collapsed');
        $(this).closest('.accordion.employees-selection__group').find('.accordion__content.collapse').removeClass('show');
    }
});

const searchStructById = function (id) {
    console.log('allData', allData)
    let i = allData.map(
        function (struct) {
            return struct.struct_id == id
        }
    ).indexOf(true);
    if (i !== -1) {
        return i;
    } else {
        return false;
    }
};
const searchUserById = function (structId, id) {
    let i = allData[structId].users.map(
        function (user) {
            return user.user_id == id
        }
    ).indexOf(true);
    if (i !== -1) {
        return i;
    } else {
        return false;
    }
};

export function Render(data) {
    if (data) {
        allData = data
    }
    RenderLeftSide(buildLeftDOM(allData));
    RenderRightSide(buildRightDOM(allData));
}
